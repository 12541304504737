import React from "react";

import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components'
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import { MenuItem } from '@material-ui/core';


import Link from "../components/Link";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import RightContainer from "../components/RightContainer";
import MUIDrawer from "../components/Drawer";

import theme from "../theme.js";

export const PostListItem = styled(ListItem)`
  font-family: Old Standard TT, serif;
  font-weight: bold;
 
`;

export const PostListItemText = styled(ListItemText)`

  font-family: Old Standard TT, serif;
  font-size: 2rem;
  font-weight: bold;

  ${props => props.theme.breakpoints.down("xs")} {
    font-size: 1.5em;
    font-weight: bold;
  }

`;

export const StyledPostLink = styled(Link)`
  font-family: Old Standard TT, serif;
  font-weight: bold;
  color: #4c494f;

`;


export const StyledBox = styled(Box)`
  height: 100;
  display: flex;
  padding: 8;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
`;

export const StyledPrevNextLink = styled(Link)`
  font-family: Old Standard TT, serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: #4c494f;
`;

export const PostDate = styled.h2`
  color: #4c494f;
  font-size: 0.8rem;
  margin-bottom: -1em;
`;


export const PostTitle = styled.h1`
  font-family: Old Standard TT, serif;
  font-size: 2rem;
  color: #4c494f;
`;

export const PostContent = styled.div`
  font-size: 1.3em;
  font-family: Lora, sans serif;
  color: #4c494f;

`;

export const MenuContext = React.createContext({
  isOpen: false
});

