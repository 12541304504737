import React, { useState, useEffect  } from 'react';

import { graphql } from 'gatsby';

import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';

import { ThemeProvider } from 'styled-components'
import theme from "../theme.js";
import {MenuContext} from '../components/PostUtils';


import Link from "../components/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
  container: {
    flexGrow: 1,
  },
  grid: {
    height: "100vh",
  },

  topgrid: {
    marginTop: "10px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
}));


export const StyledRightDiv = styled.div`
  margin: 2rem auto;
  display: flex;
  
  flex-direction: column;
  align-items: flex-end;


  ${props => props.theme.breakpoints.down("xs")} {
    align-items: center;  
    flex-direction: row;
    justify-content: center;
  }
`;


const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${props => props.theme.breakpoints.down("xs")} {
    flex-direction: row;
    align-items: center;
  }

`;

const StyledLogoTypeV = styled(Typography)`
  line-height: 1.2;
  ${props => props.theme.breakpoints.down("xs")} {
    font-size: 2rem;
    font-weight: bold;
  }
`;

const StyledLogoTypeG = styled(Typography)`
  line-height: 0.3;
  ${props => props.theme.breakpoints.down("xs")} {
    margin-left: 0.1em;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
  }
`;


const StyledRightMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;

  ${props => props.theme.breakpoints.down("xs")} {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
  }

`;

const StyledList = styled(List)`
 ${props => props.theme.breakpoints.down("xs")} {
  display: flex;
  flex-direction: row;
  padding: 0;
}
`;

const StyledListItemText = styled(ListItemText)`
  font-size: 20px;
  font-weight: bold;
  font-family:  Old Standard TT, serif;
  display: flex;
  justify-content: flex-end;
  align-items: right;
`;

const LogoLink = styled(Link)`
  color: #4c494f;
  &:hover {
    underline: none;
  };
`;

const StyledMenuLink = styled(Link)`
  color: #4c494f;
`;

export const BurgerMenu = styled.div`
  display: flex;
  position: absolute;
  right: 25px;
`;

export default function RightContainer({ }) {
  const {isOpen, setIsOpen} = React.useContext(MenuContext);
  return (
    <ThemeProvider theme={theme}>
      <StyledRightDiv>
        <LogoLink to={"/"}>
          <LogoDiv>
            <StyledLogoTypeV variant="h2" component="h2">
              Viksit
            </StyledLogoTypeV>
            <StyledLogoTypeG variant="h2" component="h2">
              Gaur
            </StyledLogoTypeG>
          </LogoDiv>
        </LogoLink>

        <Hidden smUp>
          <BurgerMenu>
            <MenuIcon onClick={() => {setIsOpen(true)}}/>
          </BurgerMenu>
        </Hidden>
        
        <Hidden xsDown>
          <StyledRightMenu>
            <StyledList dense={true}>
              <ListItem component={StyledMenuLink} to={"/about"}>
                <StyledListItemText disableTypography primary="About" />
              </ListItem>
              <ListItem component={StyledMenuLink} to={"/colophon"}>
                <StyledListItemText disableTypography primary="Colophon" />
              </ListItem>
              <ListItem component={StyledMenuLink} to={"/archive"}>
                <StyledListItemText disableTypography primary="Archive" />
              </ListItem>
            </StyledList>
          </StyledRightMenu>
        </Hidden>
        
      </StyledRightDiv>
    </ThemeProvider>
  );
};
