import React, { useState, useEffect  } from 'react';

import { ThemeProvider } from 'styled-components'
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import { MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';


import Link from "../components/Link";
import Layout from "../components/Layout";
import {MenuContext} from '../components/PostUtils';

import theme from "../theme.js";

const ContainerDiv = styled.div`
  flex-grow: 1;
  padding-left: 35px;
  padding-right: 35px;

  ${props => props.theme.breakpoints.between("sm", "xl")} {
    background: 
      linear-gradient(#4c494f, #4c494f) bottom left/ 50% 25px,
      linear-gradient(#e1b5b3, #e1b5b3) top right / 50% 25px,
      linear-gradient(#4c494f, #4c494f) top left/ 25px 50%,
      linear-gradient(#e1b5b3, #e1b5b3) bottom right / 25px 50%, #fff;
    background-repeat: no-repeat;
    padding-left: 20px;
    padding-right: 20px;

  }

  ${props => props.theme.breakpoints.down("xs")} {
    border-top: 25px solid  #e1b5b3;
    border-bottom: 25px solid #4c494f;
    padding-left: 0px;
    padding-right: 0px;
  }

`;

const DrawerPostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
 
  ${props => props.theme.breakpoints.down("xs")} {
    margin-top: 12rem; 
    margin-right: 0rem;
    margin-left: 0rem;
    min-height: auto;
    min-width: auto;
  }
`;

export const DrawerStyledRightDiv = styled.div`
  margin: 2rem auto;
  display: flex;
  
  flex-direction: column;
  align-items: flex-end;


  ${props => props.theme.breakpoints.down("xs")} {
    align-items: center;  
    flex-direction: row;
    justify-content: center;
  }
`;

export const DrawerBurgerMenu = styled.div`
  display: flex;
  position: absolute;
  right: 25px;
  top: 70px;
`;

const StyledPaper = styled.div`
   background-color:  rgba(255, 255, 255, 0.9);;
   width: 100%;
`;

export const DPostListItem = styled(ListItem)`
  font-family: Old Standard TT, serif;
  font-weight: bold;
`;

export const DPostListItemText = styled(ListItemText)`
  font-family: Old Standard TT, serif;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.theme.breakpoints.down("xs")} {
    font-size: 1.5em;
    font-weight: bold;
  }
`;

export const DStyledPostLink = styled(Link)`
  font-family: Old Standard TT, serif;
  font-weight: bold;
  color: #4c494f;
`;

const DPostList = styled(List)`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;

`;
const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export default function MUIDrawer() {
  const {isOpen, setIsOpen} = React.useContext(MenuContext);
  return (
    <div>
      <Drawer anchor={"right"}
              open={isOpen}
              PaperProps={{ component : StyledPaper }}>
        <ThemeProvider theme={theme}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
              <DrawerStyledRightDiv>
                <DrawerBurgerMenu>
                  <CloseIcon onClick={() => {setIsOpen(false)}}/>
                </DrawerBurgerMenu>
              </DrawerStyledRightDiv>
              <DrawerPostContainer>
                <DPostList dense={true}>
                  <DPostListItem
                    key="About"
                    component={DStyledPostLink}
                    to={"/about"}
                    onClick={() => {setIsOpen(false)}}>
                    <DPostListItemText
                      disableTypography
                      primary="About"/>
                  </DPostListItem>
                  <DPostListItem
                    key="Col"
                    component={DStyledPostLink}
                    to={"/colophon"}
                    onClick={() => {setIsOpen(false)}}>
                    <DPostListItemText
                      disableTypography
                      primary="Colophon"/>
                  </DPostListItem>
                  <DPostListItem
                    key="Archive"
                    component={DStyledPostLink}
                    to={"/archive"}
                    onClick={() => {setIsOpen(false)}}>
                    <DPostListItemText
                      disableTypography
                      primary="Archive"/>
                  </DPostListItem>
                </DPostList>
                <SocialIcons>
                  <DStyledPostLink to="https://www.twitter.com/viksit">
                    <TwitterIcon/>
                  </DStyledPostLink>
                  <DStyledPostLink to="https://www.instagram.com/viksit">
                    <InstagramIcon/>
                  </DStyledPostLink>
                </SocialIcons>
              </DrawerPostContainer>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Drawer>
    </div>
  );
};
